import React, { FC, useContext, useEffect, useState } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import SelectionPDF from '@components/requestForms/selection.pdf.form'
import { formatStrapiText } from '@utils/methods'
import * as pageUtils from '@templates/requests/__create.utils'
import { joinClasses } from '@utils/styles'
import { RequestFormPDFProps } from '@utils/request'
import {
	REQUEST_TN_DETAILS_STEPS_NAME,
	REQUEST_CONFIGS_KEYS
} from '@services/constants'
import { useAppSelector } from '@services/store'
import Invoice from '../invoice'
import { IInvoice } from '@services/types/invoice'
import Collapse from '../collapse'
import { IRequestCreatedState } from '@services/store/request/type'
import moment from 'moment'

interface props extends RequestFormPDFProps {
	name: string
	address?: string
	apartment?: string
	currentStep: number
	city?: string
	mapLabel: string
	postalCode?: string
	cadastralAddress?: string
	requestCreated?: IRequestCreatedState
	policy: string
}

const RequestFormPDFSummaryUrban: FC<props> = ({
	name,
	files,
	hasFileError,
	hasFileRequiredError,
	inputs,
	errors,
	inputFiles,
	setCurrentStep,
	onSetCurrentSubStep,
	onSetInputFiles,
	onFixError,
	onSetFiles,
	onSetHasFileError,
	onSetHasFileRequiredError,
	onSetInputs,
	subSteps,
	steps,
	onSetSubSteps,
	setSteps,
	address,
	apartment,
	postalCode,
	cadastralAddress,
	toPrint,
	city,
	filesInputs,
	currentStep,
	mapLabel,
	requestCreated,
	request,
	policy
}) => {
	const { pageData, authUser } = useContext<AppContextProps>(AppStateContext)
	const [width, setWidth] = useState<number>(window.innerWidth)
	const [invoice, setInvoice] = useState<IInvoice>({} as IInvoice)

	const customLocationState = useAppSelector((state) => state.request.location)
	const answers = useAppSelector((state) => state.request.configs)

	const backToForm = (step: number, subStep: string) => {
		if (setCurrentStep && onSetSubSteps && subSteps) {
			setCurrentStep(step)
			onSetSubSteps({
				...subSteps,
				position: subStep as REQUEST_TN_DETAILS_STEPS_NAME
			})
		}
	}

	const shouldCadastralNumberDisplay = (): boolean => {
		return [
			'heritageBuildingsRevitalizationProgram',
			'crackedHouses',
			'treePlanting',
			'buildingTransactionRequest',
			'municipalEvaluation'
		].includes(name)
	}

	const shouldDisplaySelectOwnedMoreThenAYearOptions = (): boolean => {
		return ['treePlanting'].includes(String(name))
	}
	const isTemporaryUseOfPesticideForm = (): boolean => {
		return ['temporaryUseOfPesticide'].includes(String(name))
	}

	const shouldDisplayAlternateCadastralNumber = (): boolean => {
		return ['treePlanting'].includes(String(name))
	}

	const shouldDisplayBuildingTransactionLabel = (): boolean => {
		return ['buildingTransactionRequest'].includes(String(name))
	}

	function handleWindowSizeChange() {
		setWidth(window.innerWidth)
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange)
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange)
		}
	}, [])

	const isMobile = width <= 768

	return (
		<div className={pageUtils.classes.containerFlex}>
			<div className={pageUtils.classes.containerItem1}>
				{!toPrint && (
					<div className={pageUtils.classes.sectionBlueTitle}>
						<h3>{formatStrapiText(pageData?.assets?.label_summary)}</h3>
					</div>
				)}
				<div
					className={joinClasses([
						pageUtils.classes.textSection,
						'textSectionUrban',
						pageUtils.classes.collapse,
						pageUtils.classes.summaryFirstCollapse,
						// pageUtils.classes.accordionTitle,
						toPrint ? pageUtils.classes.toPrintTitle : ''
					])}
				>
					{toPrint && requestCreated && (
						<div className={pageUtils.classes.toPrintTitle}>
							<Collapse
								id={2}
								buttonText={request?.title ?? ''}
								isOpen={toPrint ?? !isMobile}
								key={`request_form_request_information`}
							>
								<div className={pageUtils.classes.summarySection}>
									<h4 className={pageUtils.classes.subTitle}>
										{request?.title}
									</h4>
									<div className={pageUtils.classes.infoTitle}>
										<strong>
											{pageData?.assets?.page_myRequest_select_creationDate}
										</strong>
										<br />
										<span>{moment().format('YYYY-MM-DD HH:mm')}</span>
									</div>
									<div className={pageUtils.classes.infoTitle}>
										<strong>
											{
												pageData?.assets
													?.request_form_confirmation_request_number_text
											}
										</strong>
										<br />
										<span>{requestCreated?.code}</span>
									</div>
								</div>
							</Collapse>
						</div>
					)}

					{!toPrint && (
						<span className={pageUtils.classes.summaryTitle}>
							{request?.title}
						</span>
					)}

					<Collapse
						id={1}
						buttonText={pageData?.assets?.request_form_personal_informations}
						isOpen={toPrint}
						key={`request_form_personal_informations`}
					>
						<div
							className={joinClasses([
								pageUtils.classes.summarySection,
								'summarySectionUrban'
							])}
						>
							<div style={{ position: 'relative' }}>
								{!toPrint && (
									<a
										style={{
											display: toPrint ? 'none' : 'flex',
											position: 'absolute',
											right: '10px',
											top: '-47px',
											padding: '14px 25px',
											height: '40px',
											fontSize: '15px'
										}}
										className={joinClasses([
											pageUtils.classes.btn,
											pageUtils.classes.button,
											pageUtils.classes.buttonOutline
										])}
										onClick={() => backToForm(steps?.applicant!, '')}
									>
										{pageData?.assets?.label_modify}
									</a>
								)}
								{toPrint && (
									<h4 className={pageUtils.classes.subTitle}>
										{pageData?.assets.request_form_personal_informations}
									</h4>
								)}
							</div>
							<div className={pageUtils.classes.infoTitle}>
								<strong>{pageData?.assets?.label_fullName}</strong>
								<br />
								<span>{authUser?.profile?.fullName}</span>
							</div>
							<div className={pageUtils.classes.infoTitle}>
								<strong>{pageData?.assets?.label_email}</strong>
								<br />
								<span>{authUser?.profile?.email}</span>
							</div>
							<div className={pageUtils.classes.infoTitle}>
								<strong>{pageData?.assets?.label_address}</strong>
								<br />
								<span>
									{authUser?.profile?.address?.address
										? authUser?.profile?.address?.address + ', '
										: ''}{' '}
									<br />
									{authUser?.profile?.address?.city}
									{authUser?.profile?.address.state
										? ` (${authUser?.profile?.address.state}) `
										: ' '}
									{authUser?.profile?.address?.postalCode
										? authUser?.profile?.address?.postalCode + ', '
										: ''}{' '}
									<br />
									{authUser?.profile?.address.apartment
										? `${formatStrapiText(
												pageData?.assets?.label_apartment_office
										  )} ${authUser?.profile?.address.apartment}`
										: ''}
								</span>
							</div>
							<div className={pageUtils.classes.infoTitle}>
								<strong>{pageData?.assets?.label_telephone}</strong>
								<br />
								<span>
									{authUser?.profile?.phoneNumber ||
										authUser?.profile?.phoneNumberEvening ||
										authUser?.profile?.phoneNumberOther}
								</span>
							</div>
						</div>
					</Collapse>

					{/* <Collapse
						id={1}
						buttonText={
							shouldDisplayBuildingTransactionLabel()
								? pageData?.assets?.request_form_request_details_of_the_request
								: pageData?.assets?.label_information_request
						}
						isOpen={toPrint ?? !isMobile}
						key={`label_information_request`}
						contentClassWrapper={pageUtils.classes.contentWrapper}
					>
						{toPrint && (
							<h4 className={pageUtils.classes.subTitle}>
								{pageData?.assets?.label_information_request}
							</h4>
						)} */}

					<SelectionPDF
						name={name}
						files={files}
						hasFileError={hasFileError}
						hasFileRequiredError={hasFileRequiredError}
						inputs={inputs}
						errors={errors}
						inputFiles={inputFiles}
						setCurrentStep={setCurrentStep}
						onSetCurrentSubStep={onSetCurrentSubStep}
						onSetInputFiles={onSetInputFiles}
						onFixError={onFixError}
						onSetFiles={onSetFiles}
						onSetHasFileError={onSetHasFileError}
						onSetHasFileRequiredError={onSetHasFileRequiredError}
						onSetInputs={onSetInputs}
						subSteps={subSteps}
						steps={steps}
						onSetSubSteps={onSetSubSteps}
						isSummaryStep
						toPrint={toPrint}
						filesInputs={filesInputs}
						currentStep={currentStep}
					/>
					{/* </Collapse> */}
				</div>
				{toPrint && requestCreated && (
					<div
						className={joinClasses([
							pageUtils.classes.checkbox2Container,
							pageUtils.classes.policyContainer
						])}
					>
						<form>
							<input
								onClick={() => {}}
								value={'true'}
								checked={true}
								className={pageUtils.classes.checkbox2}
								type="checkbox"
								name="terms_and_condition"
								id="terms_and_condition"
								disabled={true}
							/>
							<label
								className={joinClasses([
									pageUtils.classes.checkbox2Label,
									pageUtils.classes.policyLabel
								])}
								htmlFor="terms_and_condition"
							>
								{policy}
							</label>
						</form>
					</div>
				)}
			</div>
			{/* <div className={pageUtils.classes.containerItem2}>
				<div className={pageUtils.classes.sectionBlueTitle}>
					<h3>{formatStrapiText(pageData?.assets.label_payment)}</h3>
				</div>
				<div className={pageUtils.classes.textSection}>
					<Invoice invoice={invoice} />
				</div>
			</div> */}
		</div>
	)
}

export default RequestFormPDFSummaryUrban
